<template>
    <div class="report-templates">
        <styled-interface>
            <template #interface-toolbar-external>
                <dealer-toolbar-global />
            </template>
            <div class="layout wrap">
                <styled-card
                    id="report-templates"
                    class="flex ma-3 xs12">
                    <template #heading>
                        Templates
                    </template>
                    <template #action-buttons>
                        <action-button
                            icon="refresh"
                            @click="getReportTemplates">
                            Refresh
                        </action-button>
                        <action-button
                            icon="plus"
                            color="#00a2ea"
                            @click="showNewTemplateModal = true">
                            Create Template
                        </action-button>
                    </template>
                    <div class="templates-container">
                        <custom-table
                            :items="formattedTemplates"
                            :fields="headers"
                            :loading="loading"
                            hide-sort
                            has-filters
                            has-menu
                            name="report-templates">
                            <template #menu="{ item }">
                                <router-link
                                    v-if="canEdit(item.agency_id)"
                                    :to="{name: 'report-builder', params: {template_id: item.id}}">
                                    Edit
                                </router-link>
                                <div class="menu-link">
                                    <span @click="duplicationConfirmation = true; duplicatedTemplate = item;">Duplicate</span>
                                </div>
                                <div class="menu-link">
                                    <span @click="deleteTemplate(item.id)">Delete</span>
                                </div>
                            </template>
                        </custom-table>
                    </div>
                </styled-card>
            </div>
        </styled-interface>
        <styled-dialog v-model="duplicationConfirmation">
            <div class="new-template-modal">
                <action-button
                    class="modal-close"
                    icon="close"
                    color="blue"
                    @click="duplicationConfirmation = false" />
                <h2>Name your new template</h2>
                <p class="duplication-subtitle">
                    This is what your template will be referenced by. It will also be displayed as the title of the report upon generation.
                </p>
                <v-form
                    v-model="duplicationValid"
                    @submit.prevent="duplicateTemplate">
                    <v-text-field
                        v-model="duplicationName"
                        class="styled-field"
                        :rules="nameRules"
                        label="Template Name"
                        required />
                    <styled-button
                        class="next-button"
                        :loading="loading"
                        :disabled="!duplicationValid || loading">
                        SAVE
                    </styled-button>
                </v-form>
                <div class="error-message">
                    {{ duplicationError }}
                </div>
            </div>
        </styled-dialog>
        <styled-dialog v-model="showNewTemplateModal">
            <div class="new-template-modal">
                <action-button
                    class="modal-close"
                    icon="close"
                    color="blue"
                    @click="showNewTemplateModal = false" />
                <h2>Name your new template</h2>
                <p>
                    This is what your template will be referenced by. It will also be displayed as the title of the report upon generation.
                </p>
                <v-form
                    id="new-template-form"
                    v-model="valid"
                    @submit.prevent="createTemplate">
                    <v-text-field
                        v-model="name"
                        :rules="nameRules"
                        name="template_name"
                        label="Template Name"
                        required />
                    <styled-button
                        class="next-button"
                        :loading="loading"
                        :disabled="!valid || loading">
                        Next
                    </styled-button>
                </v-form>
                <div class="error-message">
                    {{ errorMessage }}
                </div>
            </div>
        </styled-dialog>
        <generate-report-slideout
            v-model="showGenerateReportSlideout"
            :template-id="selectedTemplateId" />
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import CustomTable from '@/components/globals/CustomTable/index.vue';
import StyledInterface from '@/components/globals/StyledInterface';
import DealerToolbarGlobal from '@/components/globals/DealerToolbarGlobal';
import GenerateReportSlideout from './GenerateReportSlideout';
import StyledCard from '@/components/globals/StyledCard';
import StyledDialog from '@/components/globals/StyledDialog';
import StyledButton from '@/components/globals/StyledButton';
import ActionButton from '@/components/globals/ActionButton';
import TemplatesApi from '@/templates-api';
import moment from 'moment';
import { FACEBOOK_REPORT_ID } from '@/helpers/globals';

export default {
    name: 'ReportTemplates',
    components: {
        CustomTable,
        StyledInterface,
        DealerToolbarGlobal,
        GenerateReportSlideout,
        StyledCard,
        StyledDialog,
        StyledButton,
        ActionButton,
    },
    data() {
        return {
            errorMessage: '',
            loading: false,
            showNewTemplateModal: false,
            showGenerateReportSlideout: false,
            selectedTemplateId: null,
            valid: false,
            name: '',
            duplicationName: '',
            duplicationError: '',
            duplicationValid: false,
            duplicatedTemplate: null,
            duplicationConfirmation: false,
            FACEBOOK_REPORT_ID,
            nameRules: [
                v => !!v || 'Name is required',
            ],
            error: '',
            headers: [
                {
                    id: 'display_name',
                    header: 'Template Name',
                    value: 'display_name',
                    align: 'left',
                    width: '30%',
                    sortable: true,
                    type: 'component',
                    component: () => import('@/components/report-builder/templates-table/TemplateName.vue'),
                    callback: (value) => {
                        this.openTemplate(value);
                    }
                },
                {
                    id: 'pages',
                    header: 'Pages',
                    value: 'page_count',
                    align: 'left',
                    width: '10%',
                    sortable: true,
                    type: 'component',
                    component: () => import('@/components/report-builder/templates-table/PagesInfo.vue'),
                },
                {
                    id: 'created_by',
                    header: 'Created by',
                    value: 'created_by',
                    align: 'left',
                    width: '15%',
                    sortable: true,
                    filter: 'multiple',
                    placeholder: 'All',
                    type: 'single',
                },
                {
                    id: 'date_created',
                    header: 'Date Created',
                    value: 'created_at.date',
                    align: 'left',
                    width: '13%',
                    sortable: true,
                    type: 'date',
                },
                {
                    id: 'last_updated',
                    header: 'Last Updated',
                    value: 'updated_at.date',
                    align: 'left',
                    width: '13%',
                    sortable: true,
                    type: 'date',
                },
                {
                    id: 'edit',
                    header: 'Edit',
                    value: '',
                    align: 'center',
                    width: '7%',
                    sortable: false,
                    type: 'component',
                    component: () => import('@/components/report-builder/templates-table/TemplateIcons.vue'),
                    props: {
                        icon: 'edit'
                    },
                },
                {
                    id: 'generate_report',
                    header: 'Generate Report',
                    value: '',
                    align: 'center',
                    width: '7%',
                    sortable: false,
                    type: 'component',
                    component: () => import('@/components/report-builder/templates-table/TemplateIcons.vue'),
                    props: {
                        icon: 'pdf'
                    },
                    callback: (value) => {
                        this.showGenerateReportSlideout = true;
                        this.selectedTemplateId = value?.id;
                    }
                }
            ]
        };
    },
    computed: {
        ...mapState({
            currentAgencyId: (state) => state.agency.currentAgency.id,
            currentDealerId: (state) => state.dealer.currentDealerId,
            dateRange: (state) => state.metrics.dateRange,
            templates: (state) => state.report.availableTemplates,
            selectedFacebookCampaigns: (state) => state.metrics.facebook.ads.campaignsSelected
        }),
        ...mapGetters([
            'userIsAdmin',
            'currentUser',
        ]),
        formattedTemplates() {
            return this.templates.map(template => {
                return {
                    ...template,
                    page_count: template.configuration.pages.length,
                    created_by: template.agency?.name || 'BuyerBridge',
                };
            });
        }
    },
    watch: {
        currentAgencyId() {
            this.getReportTemplates();
        }
    },
    activated() {
        this.getReportTemplates();
    },
    created() {
        this.$title = 'Report Templates';
        this.templatesApi = new TemplatesApi();
    },
    methods: {
        ...mapActions([
            'setAvailableTemplates'
        ]),
        async getReportTemplates() {
            try {
                this.loading = true;
                const templates = await this.templatesApi.getAgencyTemplates(this.currentAgencyId);
                this.setAvailableTemplates(templates);
            } finally {
                this.loading = false;
            }
        },
        async createTemplate() {
            const config = {
                agencyId: this.currentAgencyId,
                name: this.name
            };
            try {
                this.loading = true;
                this.errorMessage = '';
                const response = await this.templatesApi.createBlankTemplate(config);
                const template = response.data;
                this.$router.push({ name: 'report-builder', params: { template_id: template.id } });
            } catch(error) {
                const duplication = error.response?.data?.error?.messages[0]?.includes('Duplicate') ?? false;
                if (duplication) {
                    this.errorMessage = 'Template name already exists. Please choose another Template name.';
                } else {
                    alert('An error occured when trying to create a new template.');
                    console.error(error);
                }
            } finally {
                this.loading = false;
            }
        },
        async deleteTemplate(templateId) {
            const confirmed = confirm('Are you sure you want to delete this report?');
            if(confirmed) {
                try {
                    this.loading = true;
                    await this.templatesApi.deleteTemplate(templateId);
                } catch(error) {
                    alert('You don\'t have permission to modify this template.');
                } finally {
                    this.getReportTemplates();
                }
            }
        },
        async duplicateTemplate() {
            try {
                this.loading = true;
                this.duplicationError = '';
                const initialConfig = this.duplicatedTemplate.configuration;
                // eslint-disable-next-line
                const configuration =  structuredClone(initialConfig);
                configuration.name = this.duplicationName;
                // find 'Front Cover' and set new template name there
                configuration.pages.forEach(page => {
                    if (page.title === 'Front Cover') {
                        page.slots.forEach(slot => {
                            if (slot.name === 'title') {
                                const slotValue = slot.value;
                                if (slotValue) { slot.eval = 'brand.template_name' }
                            }
                        });
                    }
                });
                const templateData = {
                    agencyId: this.currentAgencyId,
                    name: this.duplicationName,
                    config: configuration
                };
                const response = await this.templatesApi.createTemplate(templateData);
                if (response.data.id) {
                    this.duplicationName = '';
                    this.duplicationConfirmation = false;
                    this.$flash('Report duplicated successfully', 'green');
                }
            } catch(error) {
                const duplication = error.response?.data?.error?.messages[0]?.includes('Duplicate') ?? false;
                if (duplication) {
                    this.duplicationError = 'Template name already exists. Please choose another Template name.';
                } else {
                    console.error(error);
                }
            } finally {
                this.duplicatedTemplate = null;
                this.getReportTemplates();
            }
        },
        openTemplate(template) {
            this.$router.push({ name: 'report-builder', params: { template_id: template.id } });
        },
        canEdit(agencyId) {
            return this.userIsAdmin || this.currentUser.agency.id == agencyId;
        },
        previewReport(templateId) {
            const dateRange = {
                start_date: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
                end_date: moment(this.dateRange.endDate).format('YYYY-MM-DD')
            };
            let selectedFacebookCampaignIds = [];
            if (this.selectedFacebookCampaigns.length > 0) {
                selectedFacebookCampaignIds = this.selectedFacebookCampaigns.map(selectedFacebookCampaign => selectedFacebookCampaign.id);
            }
            const preview = this.$router.resolve({ name: 'report-preview', params: { template_id: templateId },query: { 'dealer_id': this.currentDealerId, ...dateRange, 'facebook[campaigns][]': selectedFacebookCampaignIds } });
            window.open(preview.href, '_blank');
        },
        downloadReport(templateId) {
            console.log(templateId);
            alert('Coming Soon!'+ templateId);
        }
    }
};
</script>
<style lang="scss" scoped>
.menu-link {
    margin-top: 5px;
    span {
        text-decoration: underline;
        color: $blue-primary;
        cursor: pointer;
    }
}
</style>

<style lang="scss">
.new-template-modal {
    .modal-close {
        position: absolute;
        top: 15px;
        right: 15px;
    }
    .next-button {
        margin-top: 15px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
}
.error-message {
    text-align: center;
    margin-top: 15px;
    color: $error-500;
}
</style>